import React, { Component } from "react";
import { baseurl, baseurlImg } from "../../../../Components/BaseUrl";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import TextField from "@material-ui/core/TextField";

import { Link } from "react-router-dom";
// import Moment from "react-moment";
// import "moment-timezone";
import cookie from "js-cookie";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Autocomplete from "@material-ui/lab/Autocomplete";

import i18next from "i18next";

const lang = localStorage.getItem("lang") || "en";
const activeUser = cookie.get("user_id");
const token = cookie.get("token");

export class SendProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      users: [],
      messages: [],
      messageBody: "",
      messageContent: 0,
      recieverUserName: "",
      recieverId: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      NewProfilePic: "",
      offset: 0,
      favouriteProps: [],
      perPage: 6,
      currentPage: 1,
      favouriteProperties: [],
      propertytypes: [],
      selectedLocation: [],
      locations: [],
      agents: [],
      selectedAgents: [],
      languages: [],
      nationality: [],
      selectedLanguages: [],
      selectedNationality: 0,
      bed: 0,
      price: 0,
      bath: 0,
      area: 0,
      type: 0,
      purpose: 0,
      furnished: 0,
      myPropertyLocation: "",
      input: {},
      errors: {},
      inputMessage: {},
      errorsTM: {},
      selectedOption: null,
      ownerdata: null
    };
  }
  handleWishList = (e) => {
    // alert(e.target.value)
    const data = { property_id: e.target.value };
    const dataForCheckBox = {
      property_id: e.target.value,
    };
    axios.post(baseurl + "wishlist", data).then((response) => {
      $("input[value=" + e.target.value + "]").trigger("click");
    });
    this.fetchFavouriteProperties();
  };

  handleLogout = () => {
    // e.preventDefault();
    cookie.remove("token");
    cookie.remove("user_id");
    cookie.remove("role");
    cookie.remove("name");
    cookie.remove("name_ar");
    cookie.remove("profile");
    cookie.remove("email");
    this.props.logout();
    window.location.reload();
    // this.props.history.push('/login')
  };
  handleFormErrorSend = () => {
    let inputMessage = this.state.inputMessage;
    let errorsTM = {};
    let formIsValid = true;
    if (!inputMessage["message"]) {
      formIsValid = false;
      errorsTM["message"] = "Message can not be Empty!";
    }

    this.setState({
      errors: errorsTM,
    });
    console.log()
    return formIsValid;
  };
  hadnleFormError = () => {
    let input = this.state.input;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!input["location"]) {
      formIsValid = false;
      errors["location"] = "Location cannot be empty!";
    }

    if (!input["price"]) {
      formIsValid = false;
      errors["price"] = "Price cannot be empty!";
    }
    if (!input["bed"]) {
      formIsValid = false;
      errors["bed"] = "Bedroom cannot be empty!";
    }
    if (!input["bath"]) {
      formIsValid = false;
      errors["bath"] = "Bathroom cannot be empty!";
    }
    if (!input["area"]) {
      formIsValid = false;
      errors["area"] = "Area cannot be empty!";
    }
    if (!input["type"]) {
      formIsValid = false;
      errors["type"] = "Property type cannot be empty!";
    }
    if (!input["purpose"]) {
      formIsValid = false;
      errors["purpose"] = "Purpose cannot be empty!";
    }
    if (typeof input["name"] !== "undefined") {
      if (!input["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  mypropertyLocationOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    console.log("selected location", e);
    this.setState({ myPropertyLocation: e.target.value, input });
    const data = {
      selectedLanguages: this.state.selectedLanguages,
      selectedNationality: this.state.selectedNationality,
      selectedLocation: e.target.value,
    };
    this.fetchAgents(data);
  };
  onChangeLanguage = (e) => {
    this.setState({ selectedLanguages: e });
    const data = {
      selectedLanguages: e,
      selectedNationality: this.state.selectedNationality,
      selectedLocation: this.state.selectedLocation,
    };
    this.fetchAgents(data);
  };
  onChangeNationality = (e) => {
    this.setState({ selectedNationality: e.target.value });
    // const bed = this.state.bed > 0 ? this.state.bed + "bedroom" : "studio";
    // const bath = this.state.bath;
    // const furnished = this.state.furnished;
    // const type = this.state.type;
    // const purpose = this.state.purpose;
    const data = {
      // messageBody: "Hi I have " + bed + " and " + bath + " bathroom " + furnished + " " + type + " for " + purpose + " in " +loc,
      // selectedAgents: this.state.selectedAgents,
      selectedLanguages: this.state.selectedLanguages,
      selectedNationality: e.target.value,
      selectedLocation: this.state.selectedLocation,
    };
    this.fetchAgents(data);
  };
  bathOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({
      bath: e.target.value,
      input,
    });
  };
  bedOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ bed: e.target.value, input });
  };
  areaOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ area: e.target.value, input });
  };
  propertytypesOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ type: e.target.value, input });
  };
  propertyPurposeOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ purpose: e.target.value, input });
  };
  furnishedOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ furnished: e.target.value, input });
  };
  priceOnChange = (e) => {
    let input = this.state.input;
    input[e.target.name] = e.target.value;
    this.setState({ price: e.target.value, input });
  };
  sendMessage = () => {
    console.log("handle form", this.hadnleFormError());
    if (this.hadnleFormError()) {
      const loc = this.state.myPropertyLocation;
      const bed = this.state.bed > 0 ? this.state.bed + "bedroom" : "studio";
      const bath = this.state.bath;
      const furnished = this.state.furnished;
      const type = this.state.type;
      const purpose = this.state.purpose;
      const data = {
        messageBody:
          "Hi I have " +
          bed +
          " and " +
          bath +
          " bathroom " +
          furnished +
          " " +
          type +
          " for " +
          purpose +
          " in " +
          loc+ "Kindly contact me with the following detail "+cookie.get('phone'),
        selectedAgents: this.state.selectedAgents,
        selectedLanguages: this.state.selectedLanguages,
        selectedNationality: this.state.selectedNationality,
      };
      axios
        .post(baseurl + "ownerMessage", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.setState({ownerdata: response.data});
        });
    } else {
      // alert("there is missing field")
    }
  };
  selectedAgentsHandle = (e) => {
    this.setState({ selectedAgents: e });
  };
  fetchData = () => {
    this.setState({ isLoading: true });
    axios
      .get(baseurl + "propertytypes")
      .then((response) => {
        console.log("property Types", response.data);
        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch();
    axios
      .get(baseurl + "features")
      .then((response) => {
        //  console.log(response.data)
        this.setState({
          isLoading: false,
          features:
            lang == "en"
              ? response.data.allfeatures_en
              : response.data.allfeatures_ar,
        });
      })
      .catch();
    axios
      .get(baseurl + "locations")
      .then((response) => {
        console.log("auto complete location", response.data);
        this.setState({
          isLoading: false,
          locations: response.data.locations,
          // subLocationSend: response.data.location[0]
        });
      })
      .catch();
  };
  fetchAgentDetail = () => {
    const token = cookie.get("token");
    axios
      .get(baseurl + "agentinfo")
      .then((response) => {
        this.setState({
          isLoading: false,
          nationality: response.data.nationality,
          // languages: response.data.languages
        });
      })
      .catch();
    axios
      .get(baseurl + "languages")
      .then((response) => {
        if (lang == "en") {
          console.log("lang", this.state.languages);
          this.setState({ languages: response.data.languages_en });
        } else {
          this.setState({ languages: response.data.languages_ar });
        }
      })
      .catch();
  };
  fetchAgents = (agentData) => {
    axios
      .post(baseurl + "fetchAgentsByLocation", agentData)
      .then((response) => {
        console.log("selected agents", response.data);
        this.setState({ agents: response.data.agents });
      })
      .catch();
  };
  componentDidMount() {
    const data = {
      selectedLocation: this.state.selectedLocation,
    };
    this.fetchData();
    this.fetchAgentDetail();
    this.fetchAgents(data);
    this.getUsers();
    const pusher = new Pusher("2955db0221fb16cb6441", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe("chat");
    channel.bind("message", (newMessage) => {
      this.setState({ messages: [...this.state.messages, newMessage] });
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }
  getUsers() {
    axios
      .get(baseurl + "chatusers", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          users: response.data.users,
        });
        console.log("Chat Users", response.data);
      });
  }

  handleUserChat = (user, e) => {
    axios
      .get(baseurl + "allmessages/" + user.id, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          messages: response.data.messages,
          messageContent: 1,
          recieverUserName: user.name,
          recieverId: user.id,
        });
        console.log("Chat Users", response.data.messages);
      });
  };
  messageSendHandler = (e) => {
   let inputMessage = this.state.inputMessage;
   inputMessage[e.target.name] = e.target.value;
    this.setState({
      // message: e.target.value, inputMessage ,
      messageBody:  e.target.value,inputMessage,
      recieverId: this.state.recieverId,
    });
  };
  submitMessage = () => {
    // e.preventDefault();
    console.log("datas", this.state.messageBody+"|"+this.state.recieverId)

   
    if (this.handleFormErrorSend()) {
      const data = {
        messageBody: this.state.messageBody,
        reciever: this.state.recieverId,
      };
      axios
        .post(baseurl + "newmessage", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data)
          this.setState({ messageBody: "" });
          //this.setState({messageBody:""})
          //this.fetchData();
        });
    }
  };

  render() {
    return (
      <div>
        {/* ============================ User Dashboard ================================== */}
        <section className=" pt-5 pb-5">
            <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-12">
                {/* <div
                  className="dashboard-body dashboard-wraper tab-content dash_user_menues flex-column"
                  id="v-pills-tabContent"
                  role="tablist"
                  aria-orientation="vertical"
                >
    */}
                    <div style={{padding: "15px"}}>
                      <div className="">
                      <div className="row">
                        <h2 className="breadcrumb-title"> {i18next.t("propertydetails")}</h2>
                        <div className="col-md-6 col-sm-6 col-md-6">
                          <label> {i18next.t("mypropertylocation")}</label>
                          <input
                            onChange={this.mypropertyLocationOnChange}
                            type="text"
                            className="form-control"
                            placeholder="Property Location"
                            name="location"
                          />
                          <span className="error">
                            {this.state.errors["location"]}
                          </span>
                        </div>
                        <div className="col-lg-g col-md-6 col-sm-6">
                          <label> {i18next.t("purpose")}</label>
                          <select
                            className="form-control"
                            onChange={this.propertyPurposeOnChange}
                            name="purpose"
                          >
                            <option value="For Rent">{i18next.t("forrent")}</option>
                            <option value="For Sale">{i18next.t("forsale")}</option>
                          </select>
                          <span className="error">
                            {this.state.errors["purpose"]}
                          </span>
                        </div>
                        <div className="col-lg-g col-md-6 col-sm-6">
                          <label>{i18next.t("type")}</label>
                          <select
                            id="propertyType"
                            name="type"
                            className="filter-input form-select mb-3"
                            onChange={this.propertytypesOnChange}
                          >
                            {this.props.location.state ? (
                              <option selected value="0">
                                {i18next.t("type")}
                              </option>
                            ) : (
                              <option selected value="0">
                                {i18next.t("type")}
                              </option>
                            )}
                            {this.state.propertytypes.map((prop, i) => (
                              lang=="en"?
                              <option value={prop.typeName_en}>
                                {prop.typeName_en}
                              </option>:
                              <option value={prop.typeName_ar}>
                                {prop.typeName_ar}
                              </option>
                            ))}
                          </select>
                          <span className="error">
                            {this.state.errors["type"]}
                          </span>
                        </div>
                        <div className="col-lg-g col-md-6 col-sm-6">
                          <label> {i18next.t("price")}</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">AED</span>
                            </div>
                            <input
                              onChange={this.priceOnChange}
                              type="number"
                              className="form-control"
                              name="price"
                              placeholder="Property Price"
                              name="price"
                            />
                          </div>
                          <span className="error">
                            {this.state.errors["price"]}
                          </span>
                        </div>
                        <div className="col-lg-3 col-md-2 col-sm-6">
                          <label> {i18next.t("furnished")}</label>
                          <select
                            onChange={this.furnishedOnChange}
                            className="form-control"
                            name="furnished"
                          >
                            <option value="Furnished"> {i18next.t("furnished")}</option>
                            <option value="Unfurnished"> {i18next.t("unfurnished")}</option>
                          </select>
                        </div>
                        {/* <div className="row col-md-12"> */}
                          <div className="col-lg-3 col-md-2 col-sm-6">
                            <label> {i18next.t("bedroom")}</label>
                            <input
                              onChange={this.bathOnChange}
                              type="number"
                              className="form-control"
                              placeholder="Bed"
                              name="bed"
                            />
                            <span className="error">
                              {this.state.errors["bed"]}
                            </span>
                          </div>
                          <div className="col-lg-3 col-md-2 col-sm-6">
                            <label> {i18next.t("bathroom")}</label>
                            <input
                              onChange={this.bedOnChange}
                              type="number"
                              className="form-control"
                              placeholder="Bath"
                              name="bath"
                            />
                            <span className="error">
                              {this.state.errors["bath"]}
                            </span>
                          </div>
                          <div className="col-lg-3 col-md-2 col-sm-6">
                            <label> {i18next.t("area")} {i18next.t("sqft")}</label>
                            <input
                              onChange={this.areaOnChange}
                              type="number"
                              className="form-control"
                              placeholder="Area"
                              name="area"
                            />
                            <span className="error">
                              {this.state.errors["area"]}
                            </span>
                          </div>
                        {/* </div> */}
                        <div className="col-md-12 pt-5 pb-5">
                          <h2 className="breadcrumb-title"> {i18next.t("agentselection")}</h2>
                          <label> {i18next.t("agentlocation")}</label>
                          {/* <input type="text" className="form-control" placeholder="Property Name" name="name" /> */}
                          <Autocomplete
                            // limitTags={3}
                            id="multiple-limit-tags"
                            options={this.state.locations}
                            onChange={(event, newValue) => {
                              this.setState({
                                selectedLocation: newValue,
                              });
                              const data = {
                                selectedLanguages: this.state.selectedLanguages,
                                selectedNationality: this.state.selectedNationality,
                                selectedLocation: newValue,
                              };
                              console.log("Autocomplete on change", newValue);
                              this.fetchAgents(data);
                              // this.fetchPropertyData(data);
                               const agentData= {selectedLanguage: this.selectedLanguage, selectedNationality: this.state.selectedNationality, selectedLocation: newValue, selectedArea: this.state.selectedArea}
                            }}
                            getOptionLabel={(option) => option.location}
                            value={this.state.selectedLocation}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                label=""
                                placeholder="Select nearest Available agent in your desired location"
                              />
                            )}
                          />
                        </div>
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <label> {i18next.t("nationality")}</label>
                            <select
                              id="propertyType"
                              name="type"
                              className="filter-input form-select mb-3"
                              onChange={this.onChangeNationality}
                            >
                              <option selected value="0">
                              {i18next.t("nationality")}
                              </option>
                              {this.state.nationality.map((prop, i) => (
                                <option value={prop.id}>
                                  {prop.country_enNationality}
                                </option>
                              ))}
                            </select>
                            <span className="error">
                              {this.state.errors["type"]}
                            </span>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-6 ">
                            <label> {i18next.t("languages")}</label>
                             <MultiSelect
                              options={this.state.languages}
                              value={this.state.selectedLanguages}
                              onChange={this.onChangeLanguage}
                              labelledBy="Select Languages"
                              defaultValue = {{ label: 'Amenties', value: 0 }}
                            />
                            <span className="error">
                              {this.state.errors["type"]}
                            </span>
                          </div>
                        </div>
                        <div style={{marginBottom: '110px'}}>
                          <label> {i18next.t("selectagents")}</label>
                          <MultiSelect
                            options={this.state.agents}
                            value={this.state.selectedAgents}
                            onChange={this.selectedAgentsHandle}
                            labelledBy="Select Agents"
                            defaultValue = {{ label: 'Amenties', value: 0 }}
                          />
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-2" style={{ paddingTop: "20px" }}>
                          <button
                            type="button"
                            className="btn book_btn theme-bg"
                            onClick={this.sendMessage}>
                             {i18next.t("send")}
                          </button>
                        </div>
                      </div>
 
                      </div>
                    </div>
                  </div>

              {/* </div> */}
        </section>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
  };
};
export default connect(null, mapDispatchToProps)(SendProperty);
