import React, { Component } from "react";
import i18next from "i18next";
import { Link } from 'react-router-dom'
import cookie from 'js-cookie'
export default class CategoriesHomeComponet extends Component {
  render() {
    const lang = localStorage.getItem("lang") || "en";
    const token = cookie.get('token');
    return (
      <div class="section pt-0">
        <div class="container">
          <div class="section-title-wrap section-header">
            {/* <h5 class="custom-primary">{i18next.t("cat_type")}</h5> */}
            <h2 lang={lang == "en" ? "en" : "ar"} class="title">{i18next.t("browsebycategory")}</h2>
          </div>

          <div style={{ margin: '5px' }} class="row">
            <div class="col-lg-4 col-md-6 col-mob">
              <Link
                to={{
                  pathname: lang == "en" ? "/for-rent/apartments/uae" : "/ar/للايجار/شقة/الامارات",
                  state: {
                    passLocation: [],
                    passsubLocationSend: [],
                    passType: lang == "en" ? "apartments" : "شقة",
                    passPurpose: 1,
                    bathRoomSelected: 0,
                    bedRoomSelected: -1,
                    frequencySelected: 0,
                    popular: "",
                    furnished: -1,
                    priceFrom: 0,
                    priceTo: 0,
                    selectedFeatures: [],
                    locForUrl: "uae",
                    furnishedActive: "furnishedActive",
                  },
                }}
                class="acr-category category-2">
                <i class="flaticon-company"></i>
                <div class="acr-category-body">
                  <h5> {i18next.t("apartments")} </h5>
                  {/* <span>90 Listings</span> */}
                </div>
              </Link>
            </div>

            <div class="col-lg-4 col-md-6 col-mob">
              <Link
                to={{
                  pathname: lang == "en" ? "/for-rent/residential-villa/uae" : "/ar/للايجار/فيلا-سكني/الامارات",
                  state: {
                    passLocation: [],
                    passsubLocationSend: [],
                    passType: lang == "en" ? "residential-villa" : "فيلا-سكني",
                    passPurpose: 1,
                    bathRoomSelected: 0,
                    bedRoomSelected: -1,
                    frequencySelected: 0,
                    popular: "",
                    furnished: -1,
                    priceFrom: 0,
                    priceTo: 0,
                    selectedFeatures: [],
                    locForUrl: "uae",
                    furnishedActive: "furnishedActive",
                  },
                }}
                class="acr-category category-2">
                <i class="flaticon-house"></i>
                <div class="acr-category-body">
                  <h5> {i18next.t("villas")} </h5>
                  {/* <span>433 Listings</span> */}
                </div>
              </Link>
            </div>

            <div class="col-lg-4 col-md-6 col-mob">
              <Link
                to={{
                  pathname: lang == "en" ? "/for-rent/office/uae" : "/ar/للايجار/مكتب/الامارات",
                  state: {
                    passLocation: [],
                    passsubLocationSend: [],
                    passType: lang == "en" ? "office" : "مكتب",
                    passPurpose: 1,
                    bathRoomSelected: 0,
                    bedRoomSelected: -1,
                    frequencySelected: 0,
                    popular: "",
                    furnished: -1,
                    priceFrom: 0,
                    priceTo: 0,
                    locForUrl: "uae",
                    selectedFeatures: [],
                    furnishedActive: "furnishedActive",
                  },
                }}
                class="acr-category category-2">
                <i class="flaticon-villa"></i>
                <div class="acr-category-body">
                  <h5> {i18next.t("offices")} </h5>
                  {/* <span>221 Listings</span> */}
                </div>
              </Link>
            </div>

            <div class="col-lg-4 col-md-6 col-mob">
              <Link
                to={{
                  pathname: lang == "en" ? "/for-rent/warehouse/uae" : "/ar/للايجار/مستودع/الامارات",
                  state: {
                    passLocation: [],
                    passsubLocationSend: [],
                    passType: lang == "en" ? "warehouse" : "مستودع",
                    passPurpose: 1,
                    bathRoomSelected: 0,
                    bedRoomSelected: -1,
                    frequencySelected: 0,
                    popular: "",
                    furnished: -1,
                    priceFrom: 0,
                    priceTo: 0,
                    locForUrl: "uae",
                    selectedFeatures: [],
                    furnishedActive: "furnishedActive",
                  },
                }}
                class="acr-category category-2">
                <i class="flaticon-apartment"></i>
                <div class="acr-category-body">
                  <h5> {i18next.t("warehouses")} </h5>
                  {/* <span>185 Listings</span> */}
                </div>
              </Link>
            </div>

            <div class="col-lg-4 col-md-6 col-mob">
              <Link
                to={{
                  pathname: lang == "en" ? "/for-rent/hotel-apartments/uae" : "/ar/للبيع/شقة/الامارات",
                  state: {
                    passLocation: [],
                    passsubLocationSend: [],
                    passType: lang == "en" ? "apartments" : "شقة",
                    passPurpose: 1,
                    bathRoomSelected: 0,
                    bedRoomSelected: -1,
                    frequencySelected: 0,
                    popular: "",
                    furnished: -1,
                    priceFrom: 0,
                    priceTo: 0,
                    locForUrl: "uae",
                    selectedFeatures: [],
                    furnishedActive: "furnishedActive",
                  },
                }}
                class="acr-category category-2">
                <i class="flaticon-beach-house"></i>
                <div class="acr-category-body">
                  <h5>{i18next.t("hotelapartments")}</h5>
                  {/* <span>230 Listings</span> */}
                </div>
              </Link>
            </div>

            <div class="col-lg-4 col-md-6 col-mob">
              <Link
                to={{
                  pathname: lang == "en" ? "/for-sale/commerical-land/uae" : "/ar/للبيع/أرض-تجارية/الامارات",
                  state: {
                    passLocation: [],
                    passsubLocationSend: [],
                    passType: lang == "en" ? "commerical-land" : "أرض-تجارية",
                    passPurpose: 2,
                    bathRoomSelected: 0,
                    bedRoomSelected: -1,
                    frequencySelected: 0,
                    popular: "",
                    furnished: -1,
                    priceFrom: 0,
                    priceTo: 0,
                    locForUrl: "uae",
                    selectedFeatures: [],
                    furnishedActive: "furnishedActive",
                  },
                }}
                class="acr-category category-2">
                <i class="flaticon-garage"></i>
                <div class="acr-category-body">
                  <h5> {i18next.t("lands")} </h5>
                  {/* <span>365 Listings</span> */}
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div class="section section-padding pt-0">
          <div class="container">
          <div class="row">
          <div style={{ margin: "0 auto" }} className="col-lg-6 center">
                <div className="cta cta-2 item1 center">
                  <i className="flaticon-sales-agent"></i>
                  <div className="cta-body">
                    <h4 lang={lang == "en" ? "en" : "ar"} >{i18next.t("areyoupropertyowner")}</h4>
                    <h5 lang={lang == "en" ? "en" : "ar"} >{i18next.t("doyouwanttosell")}</h5>
                    <a data-bs-toggle="modal" href="#login" className="btn-link">{i18next.t("registerhere")} {lang == 'en' ? <i className="fas fa-arrow-right"></i> : <i className="fas fa-arrow-left"></i>} </a>
                  </div>
                </div>
              </div> 
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="cta cta-2 item1">
                  <i class="flaticon-sales-agent"></i>
                  <div class="cta-body">
                    <h4>{i18next.t("buyyinghome")}</h4>
                    <p>   {i18next.t("buinghomeText")} </p>
                    <Link
                      to={{
                        pathname: lang == "en" ? "/for-sale/apartments/uae" : "/ar/للبيع/شقة/الامارات",
                        state: {
                          passLocation: [],
                          passsubLocationSend: [],
                          passType: lang == "en" ? "apartments" : "شقة",
                          passPurpose: 2,
                          bathRoomSelected: 0,
                          bedRoomSelected: -1,
                          frequencySelected: 0,
                          popular: "",
                          furnished: -1,
                          priceFrom: 0,
                          priceTo: 0,
                          locForUrl: "uae",
                          selectedFeatures: [],
                          furnishedActive: "furnishedActive",
                        },
                      }} class="btn-link">{i18next.t("findoutmore")} {lang == 'en' ? <i class="fas fa-arrow-right"></i> : <i class="fas fa-arrow-left"></i>}
                    </Link>
                  </div>
                </div>
              </div>
              
              <div class="col-lg-6">
                <div class="cta cta-2 item2">
                  <i class="flaticon-sold"></i>
                  <div class="cta-body">
                    <h4>{i18next.t("sellinghome")}</h4>
                    <p>{i18next.t("sellinghomeText")} </p>
                    {!token ? <a href="#login" data-bs-toggle="modal" class="btn-link">{i18next.t("registerhere")} {lang == 'en' ? <i class="fas fa-arrow-right"></i> : <i class="fas fa-arrow-left"></i>} </a> : null}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
              <div style={{ margin: "0 auto" }} className="col-lg-6 center">
                <div className="cta cta-2 item1 center">
                  <i className="flaticon-sales-agent"></i>
                  <div className="cta-body">
                    <h4 lang={lang == "en" ? "en" : "ar"} >{i18next.t("areyoupropertyowner")}</h4>
                    <h5 lang={lang == "en" ? "en" : "ar"} >{i18next.t("doyouwanttosell")}</h5>
                    <a data-bs-toggle="modal" href="#login" className="btn-link">{i18next.t("registerhere")} {lang == 'en' ? <i className="fas fa-arrow-right"></i> : <i className="fas fa-arrow-left"></i>} </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* ============================ Smart Testimonials ================================== */}
        {/* <section className="gray-simple">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7 col-md-8">
                  <div className="sec-heading center">
                    <h2>Good Reviews By Clients</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="item-slide space">
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-1.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Susan D. Murphy</h5>
                              <div className="_ovr_posts"><span>CEO, Leader</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.7</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-1.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-2.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Maxine E. Gagliardi</h5>
                              <div className="_ovr_posts"><span>Apple CEO</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.5</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-2.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-3.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Roy M. Cardona</h5>
                              <div className="_ovr_posts"><span>Google Founder</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.9</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-3.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-4.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Dorothy K. Shipton</h5>
                              <div className="_ovr_posts"><span>Linkedin Leader</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.7</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-4.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                    <div className="single_items">
                      <div className="_testimonial_wrios">
                        <div className="_testimonial_flex">
                          <div className="_testimonial_flex_first">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/user-5.jpg" className="img-fluid" alt />
                            </div>
                            <div className="_tsl_flex_capst">
                              <h5>Robert P. McKissack</h5>
                              <div className="_ovr_posts"><span>CEO, Leader</span></div>
                              <div className="_ovr_rates"><span><i className="fa fa-star" /></span>4.7</div>
                            </div>
                          </div>
                          <div className="_testimonial_flex_first_last">
                            <div className="_tsl_flex_thumb">
                              <img src="assets/img/c-5.png" className="img-fluid" alt />
                            </div>
                          </div>
                        </div>
                        <div className="facts-detail">
                          <p>Faucibus tristique felis potenti ultrices ornare rhoncus semper hac facilisi Rutrum tellus lorem sem velit nisi non pharetra in dui.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
      </div>
    );
  }
}
