import React, { Component } from 'react';
import i18next from 'i18next'
import Swal from 'sweetalert2'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'
import cookie from 'js-cookie'
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
// import { ToastContainer, toast } from 'react-toastify';
import './addlisting.css'
// import 'antd/dist/antd.css';
// import {Table} from 'antd'
import {itemRender,onShowSizeChange} from "./paginationfunction"
import "./antdstyle.css"
import { Grid } from  'react-loader-spinner'

// import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { baseurl } from '../Components/BaseUrl';
import API from '../API';
import { connect } from 'react-redux';
import { store } from '../store';

const lang = localStorage.getItem("lang") || "en";
// const token = cookie.get("token")
const userdata = store.getState(state=> state);
const loggedIn= userdata.auth.loggedIn

class MyAds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            managelistings: [],
            status: [],
            rent_count: 0,
            buy_count: 0,
            all_count: 0,
            rent_button: false,
            buy_button: false,
            all_button: true,
            selected_button: 4,
            isLoading: false,
            propertytypes: [],
            locations: [],
            filterLocation: [],
            selectedLocations: [],
            purpose: 0,
            category_id: 0,
            beds: -1,
            select_purpose: 0,
            dateFrom:new Date(),
            dateTo:new Date(),
            checkedStatus: false
        }
    }
    componentDidMount() {
        // var loadScript = function (src) {
        //     var tag = document.createElement('script');
        //     tag.async = false;
        //     tag.src = src;
        //     document.getElementsByTagName('body')[0].appendChild(tag);
        // }
        // loadScript('./vendor/global/global.min.js')
        // loadScript('./js/deznav-init.js')
        setTimeout(() => {
            this.fetchData();
        }, 500);
    }
    fetchData = () => {
        this.setState({ isLoading: true })
        API.get("getproperties")
            .then(response => {
                console.log("props", response.data)
                
                var data = response.data.managelistings
                var i;
                for (i = 0; i < data.length; i++) {
                    if (data[i].package_type == 1) { data[i].basicbutton = true } else { data[i].basicbutton = false }
                    if (data[i].package_type == 2) { data[i].featuredbutton = true } else { data[i].featuredbutton = false }
                    if (data[i].package_type == 3) { data[i].premiumbutton = true } else { data[i].premiumbutton = false }
                }
                this.setState({
                    managelistings: data,status: response.data.status,
                    basic_available: response.data.basic_available,
                    featured_available: response.data.featured_available,
                    premium_available: response.data.premium_available,
                    rent_count: response.data.rent_count, buy_count: response.data.buy_count,
                    all_count: response.data.all_count, isLoading: false
                })
                if (this.state.managelistings.length == 0) {
                    console.log("length = 0")
                } else {
                    $(document).ready(function () {
                        $('#myTable').DataTable();
                    });
                    this.setState({ isLoading: false })
                }
            })
            .catch(err => {
                console.log("error");
                this.setState({isLoading: false})
            })
            API.get("allpropertytypes")
            .then(response => {
                console.log(response.data)
                this.setState({
                    propertytypes: response.data.propertytypes
                })
            })
            .catch()
        API.get("agentfilterpendingLocation")
            .then(response => {
                console.log(response.data)
                this.setState({ filterLocation:  lang=="en"?response.data.locations:response.data.locations_ar  })
            })
            .catch(e => console.log('error'))
    }
    handelDelete = (managelisting, e) => {
         console.log("manage listing", managelisting)
        // e.preventDefault();
        if (!managelisting || managelisting < 1) {
            return
        } else {
            Swal.fire({
                title: lang=="en"?'Are you sure?':'هل انت متأكد ؟',
                text: lang=="en"?"You won't be able to revert this!":"لايمكنك الرجوع بعد هذه الخطوة",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: lang=="en"?'Yes, delete it!':'نعم ،احذف'
            }).then((result) => {
                if (result.isConfirmed) {
                    API.delete("properties/" + managelisting)
                        .then(response => {
                            Swal.fire(
                                lang=="en"?'Deleted!':'تم الحذف',
                                lang=="en"?'successfully deleted.':'تم حذف  بنجاح',
                                'success'
                            )
                            this.fetchData();
                        })
                        .catch(err => console.log(err))
                }
            })
        }
    }
    handleAll = (e) => {
        this.setState({ selected_button: 4, buy_button: false, rent_button: false, all_button: true })
        const data = { selected_button: 4, selectedLocations: this.state.selectedLocations, purpose: this.state.purpose, select_purpose: this.state.select_purpose, category_id: this.state.category_id, beds: this.state.beds }
        this.fetchDataBy(data)
    }
    handleRent = (e) => {
        this.setState({ selected_button: 5, buy_button: false, rent_button: true, all_button: false })
        const data = { selected_button: 5, selectedLocations: this.state.selectedLocations, purpose: this.state.purpose, select_purpose: this.state.select_purpose, category_id: this.state.category_id, beds: this.state.beds }
        this.fetchDataBy(data)
    }
    handleSale = (e) => {
        this.setState({ selected_button: 6, buy_button: true, rent_button: false, all_button: false })
        const data = { selected_button: 6, selectedLocations: this.state.selectedLocations, purpose: this.state.purpose, select_purpose: this.state.select_purpose, category_id: this.state.category_id, beds: this.state.beds }
        this.fetchDataBy(data)
    }
    fetchDataBy = (data) => {
        this.setState({ isLoading: true })
        API.post("agent-filterpending", data)
            .then(response => {
                var data = response.data.managelistings
                var i;
                for (i = 0; i < data.length; i++) {
                    if (data[i].package_type == 1) { data[i].basicbutton = true } else { data[i].basicbutton = false }
                    if (data[i].package_type == 2) { data[i].featuredbutton = true } else { data[i].featuredbutton = false }
                    if (data[i].package_type == 3) { data[i].premiumbutton = true } else { data[i].premiumbutton = false }
                }
                this.setState({ managelistings: data, isLoading: false })
            })
            .catch(e => this.setState({ isLoading: false }))
    }
    handleDateFrom =(e)=>{

        this.setState({dateFrom:e})
      }
  
      handleDateTo =(e)=>{
          this.setState({dateTo:e})
  
      }
    handleSearch = (e) => {
        this.setState({ isLoading: true })
        const data = {
            selected_button: this.state.selected_button,
            selectedLocations: this.state.selectedLocations,
            purpose: this.state.purpose, category_id: this.state.category_id,
            beds: this.state.beds, select_purpose: this.state.select_purpose,
            dateFrom:this.state.dateFrom,dateTo:this.state.dateTo
        }
        API.post("agent-filterpending", data)
            .then(response => {
                var data = response.data.managelistings
                var i;
                for (i = 0; i < data.length; i++) {
                    if (data[i].package_type == 1) { data[i].basicbutton = true } else { data[i].basicbutton = false }
                    if (data[i].package_type == 2) { data[i].featuredbutton = true } else { data[i].featuredbutton = false }
                    if (data[i].package_type == 3) { data[i].premiumbutton = true } else { data[i].premiumbutton = false }
                }
                this.setState({ managelistings: data, isLoading: false })
            })
            .catch(e => this.setState({ isLoading: false }))
    }
    handlePurpose = (e) => {
        e.preventDefault();
        this.setState({ select_purpose: e.target.value })
    }
    handleCategory = (e) => {
        e.preventDefault();
        this.setState({ category_id: e.target.value })
    }
    handleBedroom = (e) => {
        e.preventDefault();
        this.setState({ beds: e.target.value })
    }
    handleStatus = (st, managelisting, e) => {
        let index = this.state.managelistings.indexOf(managelisting)
        const data = { property_id: managelisting.id, status_id: st.id }
      
        API.post("updateProperty", data)
            .then(response => {
                if (response.data.success) {
                    var mydata = this.state.managelistings
                    var i;
                    for (i = 0; i < mydata.length; i++) {
                        if (i == index) {
                            mydata[i].status_id = st.id
                            mydata[i].status_en = st.status_en
                            mydata[i].status_ar = st.status_ar
                        }
                    }
                    this.setState({ managelistings: mydata, isLoading: false })
                }
            })
            .catch(e => console.log("error"))
    }

        handleDraft = (st,managelisting,e) =>{
        e.preventDefault()

        this.setState({
            checkedStatus : e.target.checked*1
        })

        console.log(e.target.checked*1)
        
        let index = this.state.managelistings.indexOf(managelisting)
        const data = { property_id: managelisting.id, activation: e.target.checked*1 }
        API.post("changepropertyStatus", data)
            .then(response => {
                // if (response.data.success) {
                //     lang=="en"?
                //     toast.success("Successfully updated to Inactive")
                //     :
                //     toast.success("تم التحويل لغير نشط")
                //     this.fetchData()
                
                // }
            })
            .catch(e => console.log("error"))
     
    }
    render() {
        const columns = [        
   
            {
              title: 'Type',
              dataIndex: 'typeName_en',
            //   onFilter: (value, record) => record.name.indexOf(value) === 0,
            //   sorter: (a, b) => a.typeName_en - b.typeName_en,
            //   sortDirections: ['descend'],

            },
            {
                title: 'Purpose',
                render:(text,record)=>(
                    <>
                  {record.purpose == 1 ? i18next.t("forrent") : i18next.t("forbuy")}
                  </>
                )
              
        
            },
              {
                  title: 'Location',
                  render:(text,record)=>(
                      <>
                    {lang == "en" ? record.emirate_en+"-"+record.area_en:record.emirate_ar+"-"+record.area_ar}
                    </>
                  )
        
              },
              {
                title: 'Price',
                dataIndex: 'price',
                // sorter: (a, b) => a.price.length - b.price.length,
              },
              {
                title: 'Beds',
                render:(text,record)=>(
                    <>
                     {record.beds > -1? record.beds > 0?record.beds:"Studio":null}
                    </>
                )
               // dataIndex: 'beds',
              //  sorter: (a, b) => a.beds.length - b.beds.length,
              },
      
              {
                  title:'status',
                  render:(text,record)=>(
                      <>
                           {record.status_id ==1? (<span className="badge light badge-danger">{lang == "en" ? record.status_en : record.status_ar}</span>):null}
                            {record.status_id ==2? (<span className="badge light badge-warning">{lang == "en" ? record.status_en : record.status_ar}</span>):null}
                            {record.status_id ==3? (<span className="badge light  badge-secondary">{lang == "en" ? record.status_en : record.status_ar}</span>):null}                                                                                                        
                            {record.status_id ==4? (<span className="badge light  badge-success">{lang == "en" ? record.status_en : record.status_ar}</span>):null}                                                                                                        
                            {record.status_id ==5? (<span className="badge light  badge-danger">{lang == "en" ? record.status_en : record.status_ar}</span>):null}                                                                                                        
                            {record.status_id ==6? (<span className="badge light  badge-danger">{lang == "en" ? record.status_en : record.status_ar}</span>):null}                                                                                                        
                            {record.status_id ==7? (<span className="badge light  badge-danger">{lang == "en" ? record.status_en : record.status_ar}</span>):null}                    
                         </>
                  )
              },
             
              {
                  title: 'Visiblity',
                  render: (text,record) =>(
                    <>
                    {/* <select className='draft' onChange={this.handleDraft.bind(this,1,record)} style={{                                                                               
                        height: '2rem',
                        border: '1px solid #cdd4df',
                        borderRadius: '0.55rem',
                        padding: '5px 15px'}}>
                              {
                                 record.activation == 1?
                                 <>
                                    <option value="1"> {lang=="en"?"Active":"نشط"}</option>
                                    <option value="0">{lang=="en"?"Inactive":"غير نشط"}</option>
                                 </>
                                 :
                                 <>
                                    <option value="0">{lang=="en"?"Inactive":"غير نشط"}</option>
                                    <option value="1">{lang=="en"?"Active":"نشط"}</option>                               
                                 </>
                             }
                    </select> */}
                    <div className="form-check form-switch">
                        { record.activation == 1?
                         <input className="form-check-input" onChange={this.handleDraft.bind(this,1,record)} 
                         type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />:
                         <input className="form-check-input" onChange={this.handleDraft.bind(this,1,record)} 
                         type="checkbox" role="switch" id="flexSwitchCheckChecked"  />
                        }
                    </div>
                    </>

                  )
              },
            {
              title: 'Action',
              render: (text, record) => (
                <div className="ms-auto">
                <Link to={"editagentProperty/" + record.id} className="btn btn-primary btn-sm m-2" title={lang=="en"?'Edit':"تعديل"}><i className="fa fa-edit"></i></Link>
                <a className="btn btn-danger btn-sm m-2" onClick={this.handelDelete.bind(this, record.id)} title={lang=="en"?'Delete':"حذف"}><i className="fa fa-trash" ></i></a>
            </div>
                ),
            }, 
          ]
        return (
            <div>
                <div className="container-fluid">
   
                {/* <ToastContainer /> */}
                    <div className="row mb-4">
                        <div className="privateListingCard">
                            <div className="col-md-12">
                                <div className="card-header">
                                    <h4 className="card-title">{lang == "en" ? "My Ads " : "قيد الانتظار"}</h4>
                                    {/* <div className="submitListing d-inline d-md-none d-lg-none">
                                            {
                                                 loggedIn?
                                                <a id="loginForModal" data-bs-toggle="modal" href="#login" role="button" class="btn-custom primary" >
                                                {i18next.t("submitlisting")} <i className="fas fa-plus" /> 
                                                </a>
                                                :
                                                <Link to={lang=="en"?"/add-listing":"/ar/add-listing"} className="btn-custom primary">{i18next.t("submitlisting")} <i className="fas fa-plus" /> </Link>
                                            }
                                     </div>   */}
                                  </div>
                                <div className="card-body">
                                    {/* <div className="mb-5">
                                        <button onClick={this.handleAll} type="button" className={this.state.all_button ? "btn btn-outline-success btn-sm" : "btn btn-outline-default btn-sm"} style={{ color: this.state.all_button ? '#33be8b' : '#fff', backgroundColor: this.state.all_button ? "" : "lightgrey" }}>{i18next.t("all")} <span className={this.state.all_button ? "badge bg-success" : "badge bg-default"} style={{ backgroundColor: this.state.all_button ? "green" : "#fff", color: this.state.all_button ? "#fff" : "#000" }}>{this.state.all_count}</span></button>
                                        <button onClick={this.handleSale} type="button" className={this.state.buy_button ? "btn btn-outline-success btn-sm" : "btn btn-outline-default btn-sm"} style={{ color: this.state.buy_button ? '#33be8b' : '#fff', backgroundColor: this.state.buy_button ? "" : "lightgrey" }}>{lang=="en"?"SALE":"للبيع"} <span className={this.state.buy_button ? "badge bg-success" : "badge bg-default"} style={{ backgroundColor: this.state.buy_button ? "green" : "#fff", color: this.state.buy_button ? "#fff" : "#000" }}>{this.state.buy_count}</span></button>
                                        <button onClick={this.handleRent} type="button" className={this.state.rent_button ? "btn btn-outline-success btn-sm" : "btn btn-outline-default btn-sm"} style={{ color: this.state.rent_button ? '#33be8b' : '#fff', backgroundColor: this.state.rent_button ? "" : "lightgrey" }}>{lang=="en"?"Rent":"للايجار"} <span className={this.state.rent_button ? "badge bg-success" : "badge bg-default"} style={{ backgroundColor: this.state.rent_button ? "green" : "#fff", color: this.state.rent_button ? "#fff" : "#000" }}>{this.state.rent_count}</span></button>
                                    </div> */}
                                    {/* <div className="row mt-10">
                                        <div className="col-md-3">
                                            <label>{lang=="en"?"Location":"الموقع"}</label>
                                            <Autocomplete
                                          
                                                //multiple
                                                options={this.state.filterLocation}
                                                onChange={(event, newValue) => {
                                                    this.setState({ selectedLocations: newValue });
                                                }}
                                                getOptionLabel={(option) => option.location}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label=""
                                                        placeholder={lang=="en"?"location":"الموقع"}
                                                        style={{textAlign:'right'}}
                                                    />
                                                )}
                                               
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <label>{lang=="en"?"Purpose":"الغرض"}</label>
                                            <select onChange={this.handlePurpose} className="form-select select2-container--default" style={{ height: '2.75rem' }} aria-label="form-select" required>
                                                <option selected value="0" >Any</option>
                                                <option value="1">{lang=="en"?"For Rent":"للايجار"}</option>
                                                <option value='2'>{lang=="en"?"For Sale":"للبيع"}</option>
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label>{lang=="en"?"Property type":"نوع العقار"}</label>
                                            <select onChange={this.handleCategory} className="form-select select2-container--default" style={{ height: '2.75rem' }} aria-label="form-select" required>
                                                <option selected value="0" >Any</option>
                                                {this.state.propertytypes.map((propertytype) => (
                                                    <option value={propertytype.id}>{lang == "en" ? propertytype.typeName_en : propertytype.typeName_ar}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-3">
                                            <label>{lang=="en"?"Beds":"عدد الغرف"}</label>
                                            <select className="form-select form-select-lg mb-3" style={{ fontSize: 'unset', height: '2.75rem' }} aria-label="form-select-lg example" onChange={this.handleBedroom}>
                                                <option selected value="-1">Any</option>
                                                <option value="0">Studio</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                                <option value="9">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="20+">20+</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-2 mb-3">
                                    <div className='col-md-5'>
                                         <label>{lang == "en" ? "Date From" : "من تاريخ"}</label>
                                         <DatePicker
                                         className="form-control" 
                                            selected={this.state.dateFrom}
                                            onSelect={this.handleDateFrom} //when day is clicked
                                            onChange={this.handleDateFrom} //only when value has changed
                                            />
                                    </div>
                                    <div className='col-md-5'>
                                        <label> {lang == "en" ? "Date To" : " الي تاريخ"}</label>
                                        <DatePicker
                                         className="form-control" 
                                                selected={this.state.dateTo}
                                                onSelect={this.handleDateTo} //when day is clicked
                                                onChange={this.handleDateTo} //only when value has changed
                                                format='yyyy-MM-dd'
                                                />
                                        </div>
                                        <div className="col-md-1 mt-4">
                                        <label style={{marginTop:'30px'}}></label>
                                            <button type="button" className="btn btn-sm btn-primary" onClick={this.handleSearch}>{lang == "en" ? "Search" : "ابحث"}</button>
                                        </div>
                                    </div> */}
                                    <div className='mt-5'/>

                                    <hr />
                                    <div class="table-responsive">
                                    {this.state.isLoading? <div style={{justifyContent:'center',display:'flex',alignItems:'center',height:'500px',verticalAlign:'middle'}}><Grid
                                    //  height="100"
                                        width="100"
                                        color='#00c5fb'
                                        ariaLabel='loading'
                                    /></div>:
                                    // <Table className="table-striped"
                                    // pagination= { {total : this.state.managelistings.length,
                                    //     showTotal : (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                    //     showSizeChanger : true,onShowSizeChange: onShowSizeChange ,itemRender : itemRender } }
                                    // style = {{overflowX : 'auto'}}
                                    // columns={columns}                 
                                    // // bordered
                                    // dataSource={this.state.managelistings}
                                    // rowKey={record => record}
                                    // // onChange={console.log("change")}
                                    // />
                                           null     }

                                    {/* <table  className="table table-striped mt-5">
                                        <thead>
                                            <tr>
                                                <th>{i18next.t("type")} </th>
                                                <th>{i18next.t("purpose")}</th>
                                                <th>{i18next.t("location")}</th>
                                                <th>{i18next.t("price")}</th>
                                                <th>{i18next.t("beds")}</th>
                                                <th>{i18next.t("listedby")}</th>
                                                <th>{i18next.t("status")}</th>
                                                <th>{lang=="en"?"Active":"نشط"}</th>
                                                <th>{i18next.t("actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody> */}
                                            {/* {!this.state.isLoading ?
                                                <>
                                                    {this.state.managelistings.length > 0 ? <>
                                                        {this.state.managelistings.map((managelisting) => (
                                                            <tr>
                                                                <td>{lang == "en" ? managelisting.typeName_en : managelisting.typeName_ar}</td>
                                                                <td>{managelisting.purpose == 1 ? <>{i18next.t("forrent")}</> : <>{i18next.t("forbuy")}</>}</td>
                                                                <td>
                                                                    {lang == "en" ? managelisting.emirate_en + "-"+managelisting.area_en : managelisting.emirate_ar+"-"+managelisting.area_ar}
                                                                    </td>
                                                                <td>{managelisting.price}</td>
                                                                <td>
                                                                {managelisting.beds > -1? managelisting.beds > 0?managelisting.beds:"Studio":null}
                                                                    </td>
                                                                <td>{lang == "en" ? managelisting.agent_en : managelisting.agent_ar}</td>
                                                                <td>
                                                    {managelisting.status_id ==1? (<span className="badge light badge-danger">{lang == "en" ? managelisting.status_en : managelisting.status_ar}</span>):null}

                                                    </td> */}
                                                                {/* <td>
                                                                <select className='draft' onChange={this.handleDraft.bind(this,1,managelisting)} style={{
                                                                       
                                                                       height: '2rem',
                                                                       border: '1px solid #cdd4df',
                                                                       borderRadius: '0.55rem',
                                                                       padding: '5px 15px'}}>
                                                                               {
                                                                                managelisting.activation == 1?
                                                                                <>
                                                                                <option value="1"> {lang=="en"?"Active":"نشط"}</option>
                                                                                <option value="0">{lang=="en"?"Inactive":"غير نشط"}</option>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                <option value="0">{lang=="en"?"Inactive":"غير نشط"}</option>
                                                                                <option value="1">{lang=="en"?"Active":"نشط"}</option>
                                                                                
                                                                                </>
                                                                                
                                                                            }
                                                                   </select>
                                                   
                                                    </td> */}
                                                 
                                                                {/* <td>
                                                                    <div className="ms-auto">
                                                                        <Link to={"editagentProperty/" + managelisting.id} className="btn btn-primary btn-sm m-2" title={lang=="en"?'Edit':"تعديل"}><i className="fa fa-edit"></i></Link>
                                                                        <a className="btn btn-danger btn-sm m-2" onClick={this.handelDelete.bind(this, managelisting.id)} title={lang=="en"?'Delete':"حذف"}><i className="fa fa-trash" ></i></a>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        )

                                                        )} */}
                                                    {/* </> :
                                                        <tr >
                                                            <td colSpan="10" style={{ backgroundColor: '#fff', textAlign: 'center' }}>No data </td>
                                                        </tr>
                                                    }
                                                </> : */}
                                                {/* <tr style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }} >
                                                    <td colSpan="10" style={{ backgroundColor: '#fff', textAlign: 'center' }}>
                                                        <div className="lds-ripple"><div></div><div></div></div>
                                                    </td>
                                                </tr>} */}
                                        {/* </tbody>
                                    </table> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setCredentials: (user) => dispatch({ type: "SET_LOGIN", payload: user }),
        logout: () => dispatch({type: "SET_LOGOUT"}),
        signup: () => dispatch({type: "SET_SIGNUP"})

    }
}

export default connect(null, mapDispatchToProps)(MyAds)
