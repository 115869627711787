import React, { Component } from "react";
import { baseurl, baseurlImg } from "../../../../Components/BaseUrl";
import axios from "axios";
import { Link } from "react-router-dom";
// import Moment from "react-moment";
// import "moment-timezone";
import cookie from "js-cookie";
import Pusher from "pusher-js";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import $ from "jquery";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { MultiSelect } from "react-multi-select-component";
import { store } from "../../../../store";
import API from "../../../../API";

const lang = localStorage.getItem("lang") || "en";
const userdata = store.getState(state=> state);
let user= userdata.auth.user
const token= userdata.auth.token
const activeUser =user.user_id;


export class ProfileSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      users: [],
      messages: [],
      messageBody: "",
      messageContent: 0,
      recieverUserName: "",
      recieverId: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      NewProfilePic: "",
      offset: 0,
      favouriteProps: [],
      perPage: 6,
      currentPage: 1,
      favouriteProperties: [],
      propertytypes: [],
      selectedLocation: [],
      locations: [],
      agents: [],
      selectedAgents: [],
      languages: [],
      nationality: [],
      selectedLanguages: [],
      selectedNationality: 0,
      bed: 0,
      price: 0,
      bath: 0,
      area: 0,
      type: 0,
      purpose: 0,
      furnished: 0,
      myPropertyLocation: "",
      input: {},
      errors: {},
      inputMessage: {},
      errorsTM: {},

    };
  }
  handleWishList = (e) => {
    // alert(e.target.value)
    const data = { property_id: e.target.value };
    const dataForCheckBox = {
      property_id: e.target.value,
    };
    API.post("wishlist", data).then((response) => {
      $("input[value=" + e.target.value + "]").trigger("click");
    });
    this.fetchFavouriteProperties();
  };

  handleLogout = () => {
    // e.preventDefault();
    // cookie.remove("token");
    // cookie.remove("user_id");
    // cookie.remove("role");
    // cookie.remove("name");
    // cookie.remove("name_ar");
    // cookie.remove("profile");
    // cookie.remove("email");
    this.props.logout();
    // window.location.reload();
    // this.props.history.push('/login')
  };
  handleFormErrorSend = () => {
    let inputMessage = this.state.inputMessage;
    let errorsTM = {};
    let formIsValid = true;
    if (!inputMessage["message"]) {
      formIsValid = false;
      errorsTM["message"] = "Message can not be Empty!";
    }

    this.setState({
      errors: errorsTM,
    });
    console.log()
    return formIsValid;
  };
  hadnleFormError = () => {
    let input = this.state.input;
    let errors = {};
    let formIsValid = true;
    //Name
    if (!input["location"]) {
      formIsValid = false;
      errors["location"] = "Location cannot be empty!";
    }

    if (!input["price"]) {
      formIsValid = false;
      errors["price"] = "Price cannot be empty!";
    }
    if (!input["bed"]) {
      formIsValid = false;
      errors["bed"] = "Bedroom cannot be empty!";
    }
    if (!input["bath"]) {
      formIsValid = false;
      errors["bath"] = "Bathroom cannot be empty!";
    }
    if (!input["area"]) {
      formIsValid = false;
      errors["area"] = "Area cannot be empty!";
    }
    if (!input["type"]) {
      formIsValid = false;
      errors["type"] = "Property type cannot be empty!";
    }
    if (!input["purpose"]) {
      formIsValid = false;
      errors["purpose"] = "Purpose cannot be empty!";
    }
    if (typeof input["name"] !== "undefined") {
      if (!input["name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["name"] = "Only letters";
      }
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  };

  sendMessage = () => {
    console.log("handle form", this.hadnleFormError());
    if (this.hadnleFormError()) {
      const loc = this.state.myPropertyLocation;
      const bed = this.state.bed > 0 ? this.state.bed + "bedroom" : "studio";
      const bath = this.state.bath;
      const furnished = this.state.furnished;
      const type = this.state.type;
      const purpose = this.state.purpose;
      const data = {
        messageBody:
          "Hi I have " +
          bed +
          " and " +
          bath +
          " bathroom " +
          furnished +
          " " +
          type +
          " for " +
          purpose +
          " in " +
          loc,
        selectedAgents: this.state.selectedAgents,
        selectedLanguages: this.state.selectedLanguages,
        selectedNationality: this.state.selectedNationality,
      };
      API.post("ownerMessage", data)
        .then((response) => {
          console.log("send Message", response.data);
        });
    } else {
      // alert("there is missing field")
    }
  };
  selectedAgentsHandle = (e) => {
    this.setState({ selectedAgents: e });
  };
  fetchData = () => {
    this.setState({ isLoading: true });
    API
      .get("propertytypes")
      .then((response) => {
        console.log("property Types", response.data);
        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch();
      API.get("features")
      .then((response) => {
        //  console.log(response.data)
        this.setState({
          isLoading: false,
          features:
            lang == "en"
              ? response.data.allfeatures_en
              : response.data.allfeatures_ar,
        });
      })
      .catch();
      API.get("locations")
      .then((response) => {
        console.log("auto complete location", response.data);
        this.setState({
          isLoading: false,
          locations: response.data.locations,
          // subLocationSend: response.data.location[0]
        });
      })
      .catch();
  };
  fetchAgentDetail = () => {
    const token = cookie.get("token");
    API.get("agentinfo")
      .then((response) => {
        this.setState({
          isLoading: false,
          nationality: response.data.nationality,
          // languages: response.data.languages
        });
      })
      .catch();
    API.get("languages")
      .then((response) => {
        if (lang == "en") {
          console.log("lang", this.state.languages);
          this.setState({ languages: response.data.languages_en });
        } else {
          this.setState({ languages: response.data.languages_ar });
        }
      })
      .catch();
  };
  fetchAgents = (agentData) => {
    API.post("fetchAgentsByLocation", agentData)
      .then((response) => {
        console.log("selected agents", response.data);
        this.setState({ agents: response.data.agents });
      })
      .catch();
  };
  componentDidMount() {
    const data = {
      selectedLocation: this.state.selectedLocation,
    };
    this.fetchData();
    this.fetchAgentDetail();
    this.fetchAgents(data);
    this.getUsers();
    const pusher = new Pusher("2955db0221fb16cb6441", {
      cluster: "ap2",
    });
    const channel = pusher.subscribe("chat");
    channel.bind("message", (newMessage) => {
      this.setState({ messages: [...this.state.messages, newMessage] });
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }
  updateprofilePic = async(e) => {
    // const data = {newProfilePic: e.target.value}
    // console.log(e.target.files[0].name)
    // e.preventDefault();
    // let files = e.target.files || e.dataTransfer.files;
    // if (!files.length)
    //       return;
    // this.createImage(files[0]);
    // //console.log(e.target.files[0])
    // const token = cookie.get("token");
    // this.setState({newProfilePic:URL.createObjectURL(e.target.files[0])})
    const data = new FormData();
    data.append("NewProfilePic", e.target.files[0]);
    console.log("data for image update", data)
    await API.post("changePicture", data)
      .then((response) => {
        console.log("Response Upload pic", response.data.user);
        // cookie.set("profile", response.data.user.profile);
        // window.location.reload();
        if(response?.data) {
          this.props.setCredentials(response.data)
        }
      });
  };
  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        NewProfilePic: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }
  getUsers() {
    API.get("chatusers", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          users: response.data.users,
        });
        console.log("Chat Users", response.data);
      });
  }

  handleUserChat = (user, e) => {
    API.get("allmessages/" + user.id, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          messages: response.data.messages,
          messageContent: 1,
          recieverUserName: user.name,
          recieverId: user.id,
        });
        console.log("Chat Users", response.data.messages);
      });
  };
  messageSendHandler = (e) => {
   let inputMessage = this.state.inputMessage;
   inputMessage[e.target.name] = e.target.value;
    this.setState({
      // message: e.target.value, inputMessage ,
      messageBody:  e.target.value,inputMessage,
      recieverId: this.state.recieverId,
    });
  };
  submitMessage = () => {
    // e.preventDefault();
    console.log("datas", this.state.messageBody+"|"+this.state.recieverId)  
    if (this.handleFormErrorSend()) {
      const data = {
        messageBody: this.state.messageBody,
        reciever: this.state.recieverId,
      };
      API.post("newmessage", data)
        .then((response) => {
          console.log(response.data)
          this.setState({ messageBody: "" });
          //this.setState({messageBody:""})
          //this.fetchData();
        });
    }
  };

  render() {
    return (
      <div>
        {/* ============================ User Dashboard ================================== */}
        <section className="gray pt-5 pb-5">
          <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="dashboard-body dashboard-wraper tab-content dash_user_menues flex-column"
                  id="v-pills-tabContent"
                  role="tablist"
                  aria-orientation="vertical">
                <div className="dash_user_avater">
                    <img
                      src={baseurlImg +"/uploads/profiles/"+ user.profile }
                      className="img-fluid avater"
                      alt
                    />
                    {/* <button
                      id=""
                      className="changeProfileButton"
                      data-toggle="modal"
                      data-bs-target="#changeProfilePicture"
                    >
                      <i className="fa fa-camera" />
                    </button> */}
                    <div class="changeProfileButton">
                      <i className="fa fa-camera" />
                      <input
                        name="Select File"
                        onChange={this.updateprofilePic}
                        type="file"
                      />
                    </div>
                  </div>
                    <div class="">
                      <div className="">
                        <div className="dash-msg-inbox">
                        <div className="frm_submit_block">
                          <h4>{lang=="en"?"Change Your Password":"تغيير كلمة السر"}</h4>
                          <div className="frm_submit_wrap">
                            <div className="form-row">
                              <div className="form-group col-lg-6 col-md-6">
                                <label>{lang=="en"?"Old Password":"كلمة السر القديم"}</label>
                                <input
                                  type="password"
                                  onChange={this.oldPassword}
                                  className="form-control"
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>{lang=="en"?"New Password":"كلمة السر الجديد"}</label>
                                <input
                                  type="password"
                                  onChange={this.newPasswordChange}
                                  className="form-control"
                                  required
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>{lang=="en"?"Confirm password":"تاكيد كلمة السر"}</label>
                                <input
                                  type="password"
                                  onChange={this.confirmPassword}
                                  className="form-control"
                                  required
                                />
                              </div>
                              <div className="form-group col-lg-4 col-md-4">
                                <button onClick={this.savePassChange} className="profile-btn-save"  type="submit">
                                  {lang=="en"?"Save Changes":"حفظ التغيرات"}
                                  
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
        </section>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: "SET_LOGOUT" }),
    updateprofilePic: () => dispatch({ type: "SET_PROFILE" }),

  };
};
export default connect(null, mapDispatchToProps)(ProfileSetting);
